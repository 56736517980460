declare const require
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./Package.json');
text = text[localize];
const apiKey = process.env.API_KEY;

import React, {useState, useEffect} from 'react';
import './Package.scss';

const Package = (props) => {
  const [sessionPackage, setPackageList] = useState([])
  const [mainOrderCode, setMainOrderCode] = useState('')
  const isSchoolGraduationOrder = props.isSchoolGraduationOrder

  let slug = props.city.slug
  let moment_slug = props.moments.slug

  useEffect(() => {
    setMainOrderCode(props.main_order_code ? props.main_order_code : '-')
    packageList()
  },[])

  const packageList = async() => {
    document.querySelector('.Package__ProcessingLoading').classList.remove('hidden')
    return await fetch(window.location.origin + "/api/v2/booking-packages?city_id=" + props.city.id + "&occasion_id=" + props.moments.id + "&localized_to=" + props.convertionCurrency, {
      method: "GET",
      headers: {
        "API-KEY": apiKey,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      return response.json()
    })
    .then(res => {
      setTimeout(function() {
        document.querySelector('.Package__ProcessingLoading').classList.add('hidden');
      }, 3000)
      setPackageList(res.session_packages)

    })
    .catch(error => console.log(error))
  }

  const updatePackage  = (e) => {
    // show package name and price
    document.querySelector('.PhotoDetail__PhotoSession .PhotoDetail__Info').textContent = e.getAttribute('data-name')
    //show tax in mobile and desktop
    if(e.getAttribute('data-price-without-discount') > 0) {
      document.querySelector('.PhotoDetail__Price').classList.add('red')
      document.querySelector('.PhotoDetail__PriceMobile').classList.add('red')
    }
    // package detail in mobile view
    document.querySelector('.PhotoDetail__Price').textContent = e.getAttribute('data-price')
    document.querySelector('.PhotoDetail__PriceMobile').textContent = e.getAttribute('data-price')
    // approximate in localize currency
    if (props.supportLocalizedCurrency) { 
      document.querySelector('.PhotoDetail__LocalizePrice').textContent = e.getAttribute('data-local-price') 
      document.querySelector('.PhotoDetail__LocalizePriceMobile').textContent = e.getAttribute('data-local-price')
    }
 
    let duration = (e.getAttribute('data-duration') > 1) ? text.more_than_one_hour : text.hour_duration
    duration = e.getAttribute('data-duration') + ' ' + duration
    document.querySelector('.PhotoDetail__Duration span').textContent = duration
    let edited = document.querySelector('.PhotoDetail__EditedPhotos span').textContent
    edited = e.getAttribute('data-edited') + edited
    document.querySelector('.PhotoDetail__EditedPhotos span').textContent = edited
    let downloadable = document.querySelector('.PhotoDetail__Downloadable span').textContent
    downloadable = e.getAttribute('data-downloadable') + ' ' + downloadable
    document.querySelector('.PhotoDetail__Downloadable span').textContent = downloadable
    
    if (isSchoolGraduationOrder) {
      document.querySelector('.PhotoDetail__Price').textContent = e.getAttribute('data-school-price')
      document.querySelector('.PhotoDetail__PriceMobile').textContent = e.getAttribute('data-school-price')
      document.querySelector('.PhotoDetail__Duration span').textContent = "Up to 15 Minutes Photoshoot"
    }
  }

  // clevertap
  const triggerCleverTap = async (price, slashPrice, slug, moment_slug, expected_duration) => {
    try{
      return await fetch(window.location.origin+'/en/tracking/choose_package', {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({price: price, slashPrice: slashPrice, slug: slug, moment_slug: moment_slug, duration: expected_duration, main_order_code: mainOrderCode})
      }).then(() => { return });
    }catch(err){
      console.error(err)
    }
  }

  const convertToIDR = (currency, value) => {
    let toString = String(value),
        divided   = toString.length % 3,
        rupiah  = toString.substr(0, divided),
        thousand  = toString.substr(divided).match(/\d{3}/g);
      
    if (thousand) {
      let separator = divided ? '.' : '';
      rupiah += separator + thousand.join('.');
    }

    if (isSchoolGraduationOrder) {
      return `${currency} ${rupiah}`
    } else {
      let approx = "Approx. " + currency + " " + rupiah
      return approx;
    }
  }

  const setSelected = (e) => {
    let thisPackage = e.currentTarget.parentElement.parentElement
    thisPackage.classList.add('selected-package')
    let packageInput =  (document.querySelector(".Reserve__PackageInput") as HTMLInputElement)
    packageInput.value  = thisPackage.getAttribute('data-duration')
    packageInput.dispatchEvent(new Event('change'))
    updatePackage(thisPackage) 
    let price_without_discount = thisPackage.getAttribute('data-price-without-discount'),
        expected_duration = thisPackage.getAttribute('data-duration'),
        price = 0,
        slashPrice = 0
    if (price_without_discount > 0)
    {
      price = thisPackage.getAttribute('data-price-without-discount')
      slashPrice = thisPackage.getAttribute('data-price').replace("USD ", "")
    } else {
      price = thisPackage.getAttribute('data-price').replace("USD ", "")
      slashPrice = 0
    }
    triggerCleverTap(price, slashPrice, slug, moment_slug, expected_duration)
  }

  const closeTooltip = () => {
    document.querySelector('.Package__OverlayTooltip').classList.add('hide')
  }

  const packageHeader = (value, index) => {
    if (localize == 'de') {
      return `${value.name.match(/\d+/g).join([])} Stunden Fotoshooting`
    } else if (isSchoolGraduationOrder) {
      return `Package ${index + 1}`
    } else {
      return `${value.name}`
    }
  }

  const packagePrice = value => {
    if (isSchoolGraduationOrder) {
      return `${convertToIDR(value.currency, value.price)}`;
    } else {
      const showPrice = (value.price_without_flash_discount > 0) ? value.price_without_flash_discount : value.price
      return `${value.currency} ${showPrice}`
    }
  }

  const openTooltip = () => {
    if (document.querySelector('.Package__List').getAttribute('data-tax') === "false") {
      document.querySelector('.Package__OverlayTooltip').classList.remove('hide')
    }
  }

  const printerousInfo = (
    <div className="Package__Info4" >
      <div className="Package__Icon" >
        <img className="printerous-logo" src="https://cdn.sweetescape.com/misc/landing/binus-graduation/logo_printerous.png" />
      </div>

      <div className="Package__Text" >
        Photo Print Voucher on Printerous
      </div>
    </div>
  )

  const packageTime = value => {
    if (isSchoolGraduationOrder) {
      return (
        <div className="Package__Text" >
          Up to 15 Minutes Photoshoot
        </div>
      )
    } else {
      return (
        <div className="Package__Text" >
          {value.hour_duration + ' '}
          {(value.hour_duration > 1) ? text.more_than_one_hour : text.hour_duration}
        </div>
      )
    }
  }

  const test = sessionPackage.map(function(value, index){
    let downloadablePhotos =  (value.downloadable_photos >= 5000)  ?  'All' :  value.downloadable_photos
    const priceHandler =  ()  =>  {
      if (value.price_without_flash_discount > 0) {
        return 'Package__SlashPrice'
      } else{
        return (value.is_best_value) ? 'Package__BestValuePrice' : 'Package__Price'
      }
    }
    const  useTooltip = () => {
      return (value.tax_included === false) ? " tooltip" : ""
    }
    return (
      <div key={index}
      data-index={index + 1}
      data-duration={value.hour_duration} 
      data-name={packageHeader(value, index)}
      data-edited={value.edited_photos}
      data-tax={value.tax_included}
      data-local-price = {convertToIDR(value.local_currency, value.local_price)}
      data-downloadable={downloadablePhotos}
      data-price={value.currency + " " + value.price}
      data-price-without-discount = {value.price_without_flash_discount}
      data-school-price={convertToIDR(value.currency, value.price)}
      className="Package__List">
        {value.is_best_value === true &&
          <div className="Package__BestValueLine" />
        }
        <div className="Package__Header">
          {packageHeader(value, index)}
          {value.is_best_value === true &&
            <div className="Package__BestValueTag">
              <span>{text.best_value}</span>
            </div>
          }
        </div>
        <div className={`Package__Body ${isSchoolGraduationOrder && `customBody`}`} >
          <div className="Package__Info1" >
            <div className="Package__Icon" >
              <img src="https://cdn.sweetescape.com/misc/booking/ico-starting-time.svg" />
            </div>
            
            {packageTime(value)}
          </div>

          <div className="Package__Info2" >
            <div className="Package__Icon" >
              <img src="https://cdn.sweetescape.com/misc/booking/ico-edited.svg" />
            </div>
            
            <div className="Package__Text" >
              {value.edited_photos + text.edited_photos}
            </div>
          </div>

          <div className="Package__Info3" >
            <div className="Package__Icon" >
              <img src="https://cdn.sweetescape.com/misc/booking/ico-downloadables.svg" />
            </div>
            
            <div className="Package__Text" >
              {downloadablePhotos + ' ' + text.downloadable_photos}
            </div>
          </div>

          {isSchoolGraduationOrder && index == 1 && printerousInfo}
                  
        </div>
        <div className="Package__SeparatorLine" />
        <div className="Package__Footer" >
          <div className={"Package__PriceWrapper hidden-md-down" + useTooltip()} >
            { !isSchoolGraduationOrder && value.tax_included === false &&
              <span className="tooltiptext">
                {text.tooltip_title}
                <p>
                  {text.tooltip_body}
                </p>
              </span>
            }
            <div className="Package__WrapPrice">
              {value.price_without_flash_discount > 0 &&
                <div className="Package__SlashedPrice">
                  {value.currency + ' ' + value.price}
                </div>
              }
              <div className={`${priceHandler()} ${isSchoolGraduationOrder && 'customPrice'}`}>
                {packagePrice(value)}
              </div>
              {!isSchoolGraduationOrder && value.tax_included === false &&
                <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
              }
            </div>

            {!isSchoolGraduationOrder && props.supportLocalizedCurrency &&
              <div className="Package__LocalizePrice">{convertToIDR(value.local_currency, value.local_price)}</div>
            }
          </div>
          
          {/* for mobile */}
          <div className="Package__PriceWrapper hidden-lg-up" onClick={openTooltip}>
            <div className="Package__WrapPrice">
              {value.price_without_flash_discount > 0 &&
                <div className="Package__SlashedPrice">
                  {value.currency + ' ' + value.price}
                </div>
              }
              <div className={`${priceHandler()} ${isSchoolGraduationOrder && 'customPrice'}`}>
                {packagePrice(value)}
              </div>
              { !isSchoolGraduationOrder && value.tax_included === false &&
                <img src="https://cdn.sweetescape.com/misc/booking/ico_tooltip@1x.svg" />
              }
            </div>
            
            {props.supportLocalizedCurrency &&
              <div className="Package__LocalizePrice">{convertToIDR(value.local_currency, value.local_price)}</div>
            }
          </div>

          <div className="Package__SelectButton"  onClick={setSelected}>
            {text.button_select}
          </div>
        </div> 
      </div>
    );
  })

  return(
    <div className="Package">
      <div className="Package__ContainerHack container">
        <div className="Package__Container">
          {test}
        </div>
        {props.hcCust &&
          <p className="col-xs-12 Package__HolidayCheckTip">*{text.hc_tip}</p>
        }
        {isSchoolGraduationOrder &&
          <p className="col-xs-12 Package__HolidayCheckTip">
            *Printerous voucher code will be sent seperately to your email after you’ve paid the photoshoot.
          </p>
        }
      </div>
      <div className = "Package__ProcessingLoading hidden" >
        <div className = "Package__ProcessingLoadingOverlay" >
          <div className ="Package__ProcessingLoadingContainer" >
            <div className = "Package__ProcessingLoadingLogo" >
              <img src = 'https://cdn.sweetescape.com/misc/svg/sweetescape-logo-white.svg' />
            </div>
            <div className = "Package__ProcessingLoadingSpinner" />
          </div>
        </div>
      </div>
      {/* for exclude tax modal */}
      <div className="Package__OverlayTooltip hide">
        <div className="Package__Modal">
          <div className=" Package__ModalTitle">
            {text.tooltip_title}
          </div>
          <div className="Package__ModalContent">
            {text.tooltip_body}
          </div>
          <div className="Package__ModalButton"  onClick={closeTooltip}>
            Got It
          </div>
        </div>
      </div>
    </div>
  );
};
export default Package
