declare const require;
const localize = document.querySelector("input[name='language']").getAttribute("value")
let text = require('./PhotoDetail.json');
text = text[localize];
const apiKey = process.env.API_KEY;
let meeting_location = [],
    photoTimeId = "",
    getLastText,
    isAvailable = true,
    clevertapData = {}

import React, {useState,  useEffect, useLayoutEffect} from 'react';
import './PhotoDetail.scss';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from'../Components/SelectSweetEscape/SelectSweetEscape'
import SelectCustom from'../Components/SelectSweetEscape/SelectCustom'
import Policies from "../Components/Policies/Policies";
import parse from 'html-react-parser';

// clevertap
const triggerCleverTap = async(city, moments, selectedDate, expectedDuration, price, slashPrice, order_code) => {
  try{
    return await fetch(window.location.origin+'/en/tracking/session_pick_date', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({slug: city,
                            moment_slug: moments,
                            selectedDate: selectedDate,
                            duration: expectedDuration,
                            price: price, slashPrice: slashPrice,
                            order_code: order_code})
    }).then(() => { return });
  }catch(err){
    console.error(err)
  }
}

const PhotoDetail = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [getPlaceHolderTime, setPlaceHolderTime] = useState(text.placeholder_schedule)
  const [getSchedule, setSchedule] = useState([{'value': '', 'label': ''}]);
  const [submitUrl, setSubmitUrl] = useState(`/${localize}/main_orders/create`);
  const [meetingLocationList, setMeetingLocation] = useState(props.main_order.meeting_location_list);
  const [isGetScheduleLoaded, setGetScheduleLoaded] = useState(null);
  const [isPackageDone, setPackageDone] = useState(null);
  const [windowSize, setWindowSize] = useState(null)
  const [visibility, setVisibility] = useState('')
  const [booths, setBooths] = useState([])
  const [selectedTime, setSelectedTime] = useState('')

  let localTimeZone = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1],
      minBookingDate = new Date(props.min_booking_date + ' ' + localTimeZone),
      order_code = props.main_order_code ? props.main_order_code : '-'
  const isSchoolGraduationOrder = props.isSchoolGraduationOrder
  const selectedPackage = document.querySelector(".selected-package");

  //this function set value when in edit page, and triger function for datepicker hack
  useEffect(() => {
    //getMeetingLocation()
    document.querySelector('.PhotoDetail__DatePicker').addEventListener('focus', addBorderDatePicker)
    document.querySelector('.PhotoDetail__DatePicker').addEventListener('blur', changeArrowDown)
    document.querySelector('.PhotoDetail__DatePicker').addEventListener('keydown', function(e) {
      e.preventDefault();
      e.stopPropagation();
    })
    if (props.main_order.photo_date !== null) {
      if (document.querySelector("input[name='language']").getAttribute("value") == 'de') {
        setSubmitUrl('/de/main_orders/'+window.location.pathname.split('/')[3]+'/update')
      } else {
        setSubmitUrl('/en/main_orders/'+window.location.pathname.split('/')[3]+'/update')
      } 
      photoTimeId = props.main_order.start_schedule.id
      if (props.main_order.order_information !== null) {
        localStorage.setItem("otherValue", props.main_order.order_information.value )
      }
    }

    if (isSchoolGraduationOrder) { setVisibility('hidden') }
  }, [])


  //this function add event listener for phototime meeting location and call getphototime function when package  selected
  useLayoutEffect(() => {
    if (props.main_order.photo_date !== null) { 
      window.addEventListener('load', function() {
        document.querySelector('.PhotoTime .SelectSweetEscape__SelectInput').addEventListener('click', photoTimeSetting)
        document.querySelector('.MeetingLocation .SelectSweetEscape__SelectInput').addEventListener('click', meetingLocationSetting)
        document.querySelector('.Reserve__PackageInput').addEventListener('change', function(){
          getPhotoTime(new Date(props.main_order.photo_date)) 
        })
      })
    }
    else {
      window.addEventListener('load', function() {
        document.querySelector('.Reserve__PackageInput').addEventListener('change', function(){
          getPhotoTime(minBookingDate);
          document.querySelector('.MeetingLocation .SelectSweetEscape__SelectInput').addEventListener('click', meetingLocationSetting)
        })
      })
    }
    if (isSchoolGraduationOrder) {
      photoTimeId = selectedTime
      setTimeout(() => {
        photoTimeSetting()
      }, 1500)
    }
  })

  //this function handle calendershown in mobile and desktop
  useEffect(() => {
    (window.innerWidth <= 767) ? setWindowSize(1) : setWindowSize(2) 
    window.addEventListener('resize', function() {
      (window.innerWidth <= 767) ? setWindowSize(1) : setWindowSize(2)
    })
  }
  , [windowSize])

  //this function handle set phototime and meeting location after schedule loaded
  useEffect(() => {
    if (isGetScheduleLoaded) { 
      if (props.main_order.photo_date !== null) {
        setTimeout(function() {
          photoTimeSetting();
          document.querySelector('.PhotoDetail__Loading').classList.add('hidden')
          
          if (isSchoolGraduationOrder) { return }
          let meetingLocation = (document.querySelector('.PhotoDetail__MeetingLocation .SelectSweetEscape__SelectInput') as  HTMLSelectElement)
          meetingLocation.value = props.main_order.main_order_snapshot.meeting_location_id
          meetingLocation.dispatchEvent(new Event('click'));
          (document.querySelector('.PhotoDetail__MeetingLocation .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).value = meetingLocation.options[meetingLocation.value].textContent
        }, 1000)
      }
      else {
        setTimeout(function() {
          photoTimeSetting();
          document.querySelector('.PhotoDetail__Loading').classList.add('hidden')
            if(!photoTimeId){setPlaceHolderTime(getLastText)}
        }, 1500)

      }
      setGetScheduleLoaded(false)
    }
  }, [isGetScheduleLoaded])

  const userFormatDate = (normalDate) => {
    return normalDate.getDate() +' '+ text.month_name[normalDate.getMonth()] +' '+ normalDate.getFullYear()
  }

  const  errorHandlerPhotoTime = (schedule_id, isAvailable) => {
    let errorPhotoTime = [];
    setTimeout(function(){
      if (!schedule_id) {
        (document.querySelector('.PhotoTime .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).classList.add('error')
        document.querySelector('.PhotoDetail__ErrorMessagePhotoTime').textContent = text.photo_time_error
        document.querySelector('.PhotoDetail__ErrorMessagePhotoTime').classList.remove('hidden')
        errorPhotoTime.push(true)
      }
      if (!isAvailable) {
          (document.querySelector('.PhotoTime .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).classList.add('error')
          document.querySelector('.PhotoDetail__ErrorMessagePhotoTime').textContent = text.no_photographer_available_error
          document.querySelector('.PhotoDetail__ErrorMessagePhotoTime').classList.remove('hidden')
          errorPhotoTime.push(true)
      }
      if (errorPhotoTime.length > 1) {return true;}
    }, 400)
  }
  
  //this function handle error for schedule, meeting location and meeting location other
  const errorHandler = (date, schedule_id, meeting_location_id, meeting_location_detail) => { 
    let errorCarrier = [];
    errorCarrier.push(errorHandlerPhotoTime(schedule_id, isAvailable))
    if(!meeting_location_id.value) {
      (document.querySelector('.MeetingLocation .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).classList.add('error')
      document.querySelector('.PhotoDetail__ErrorMessageMeetingLocation').classList.remove('hidden') 
      errorCarrier.push(true)
      document.querySelector('.MeetingLocation .SelectSweetEscape__SelectInput').addEventListener('click', function() {
        (document.querySelector('.MeetingLocation .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).classList.remove('error')
        document.querySelector('.PhotoDetail__ErrorMessageMeetingLocation').classList.add('hidden')
      })
    }
    if (meeting_location_id.value !== '5') {
      if (meeting_location_detail.replace(/\s/g, '').length <= 0) {
        (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).classList.add('error')
        document.querySelector('.PhotoDetail__ErrorMessageDetail').classList.remove('hidden')
        errorCarrier.push(true)
        document.querySelector('.PhotoDetail__MeetingLocationDetail').addEventListener('change', function() {
          (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).classList.remove('error')
          document.querySelector('.PhotoDetail__ErrorMessageDetail').classList.add('hidden')
        })
      }
    }
    return errorCarrier
  }
  
  const schoolOrderErrorCheck = (schedule_id, checkedBooth) => {
    let errorCarrier = [];

    errorCarrier.push(errorHandlerPhotoTime(schedule_id, isAvailable))

    if (!checkedBooth) {
      const boothItems = document.querySelectorAll('.PhotoDetail__BoothAttr')
      const boothErrorMsg = document.querySelector('.PhotoDetail__ErrorMessageBooth')

      boothErrorMsg.classList.remove('hidden')

      boothItems.forEach(el => el.classList.add('error'))

      boothItems.forEach(el => {
        el.addEventListener('click', () => {
          boothItems.forEach(el => el.classList.remove('error'))
          boothErrorMsg.classList.add('hidden')
        })
      })
      errorCarrier.push(true)
    }
    return errorCarrier
  }

  //this function for submit all the data
  const submitOrder  = async(e)  =>  {
    let csrf;
    const metaSelector = document.querySelector("meta[name='csrf-token']");
    csrf = (metaSelector !== null) ? metaSelector.getAttribute("content") : "";

    let getDate = (document.querySelector('.PhotoDetail__DatePicker') as HTMLInputElement).value,
        date = (new Date(getDate)),
        getTimeZone = (date.getTimezoneOffset() / -60),
        dateValue = new Date(date.getFullYear(), date.getMonth(), date.getDate(), getTimeZone),
        schedule_id = (document.querySelector('.PhotoDetail__PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement).value,
        meeting_location_id = (document.querySelector('.PhotoDetail__MeetingLocation .SelectSweetEscape__SelectInput') as HTMLSelectElement),meeting_location_detail = (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).value,
        additional_info = (document.querySelector('.PhotoDetail__AdditionalInfo textarea') as HTMLInputElement).value,
        expected_duration = document.querySelector('.selected-package').getAttribute('data-duration'),
        photo_quota  =  document.querySelector('.selected-package').getAttribute('data-downloadable'),
        city_slug = props.city.slug,
        occasion_id = props.moments.id,
        otherValue = localStorage.getItem("otherValue"),
        anyError = false;

    // school graduation order
    const packageIndex = document.querySelector('.selected-package').getAttribute('data-index')
    const checkedBooth = document.querySelector('input[name="booth"]:checked') as HTMLInputElement

    if (isSchoolGraduationOrder) {
      anyError = schoolOrderErrorCheck(schedule_id, checkedBooth).includes(true)
    } else {
      anyError = errorHandler(getDate, schedule_id, meeting_location_id, meeting_location_detail).includes(true)
    }

    if (anyError) {
      return false;
    } else {
      loadingButtonHandler();
    }

    return await fetch(window.location.origin+submitUrl, {
      method: "POST",
      headers: {
        'X-CSRF-Token': csrf,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({main_order: {
        city_slug: city_slug,
        city_support_one_hour: props.city.support_one_hour,
        platform: props.platform,
        schedule_id: schedule_id,
        photo_date: dateValue,
        expected_duration: expected_duration,
        meeting_point: meeting_location_detail,
        note: additional_info,
        package_index: packageIndex,
        checked_booth: checkedBooth && checkedBooth.value
      },
        city_id: props.city.id,
        quota: photo_quota,
        occasion_and_meeting_point_data: {
          occasion_id: occasion_id,
          occasion_others_details: otherValue,
          meeting_location_id: meeting_location_id.value
        }
      })
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res.error_code == "photo_date_empty") {
          document.querySelector('.PhotoDetail__ErrorMessagePhotoDate').classList.remove('hidden') 

          let submitDesktop = (document.querySelector('.PhotoDetail__Submit') as HTMLElement)
          let submitMobile = (document.querySelector('.PhotoDetail__ButtonMobile') as HTMLElement)

          submitDesktop.classList.remove('Unclickable')
          submitDesktop.textContent = handleSubmitButton() ;
          submitMobile.classList.remove('Unclickable')
          submitMobile.textContent = handleSubmitButton(); 
          document.querySelector('.PhotoDetail__RollLoadingMobile').classList.add('hidden');
          document.querySelector('.PhotoDetail__RollLoading').classList.add('hidden');
        } else {
          if (res.redirect_to) {
            localStorage.removeItem('btnSubmitCheckout')
            localStorage.setItem('btnSubmitCheckout', 'true');
            window.location.href = window.location.origin+res.redirect_to
          }
          else {
            loadingButtonHandler();
          }
        }
      })
      .catch(error => console.log(error))
  }

  const loadingButtonHandler = () => {
    let submitDesktop = (document.querySelector('.PhotoDetail__Submit') as HTMLElement)
    let submitMobile = (document.querySelector('.PhotoDetail__ButtonMobile') as HTMLElement)
    if (submitDesktop.classList.contains('Unclickable')  || submitMobile.classList.contains('Unclickable'))  {
      submitDesktop.classList.remove('Unclickable')
      submitDesktop.textContent = handleSubmitButton() ;
      submitMobile.classList.remove('Unclickable')
      submitMobile.textContent = handleSubmitButton(); 
      document.querySelector('.PhotoDetail__RollLoadingMobile').classList.add('hidden');
      document.querySelector('.PhotoDetail__RollLoading').classList.add('hidden');
    }
    else {
      submitDesktop.classList.add('Unclickable')
      submitDesktop.textContent =  "";
      submitMobile.classList.add('Unclickable')
      submitMobile.textContent =  "";
      document.querySelector('.PhotoDetail__RollLoadingMobile').classList.remove('hidden');
      document.querySelector('.PhotoDetail__RollLoading').classList.remove('hidden');
    }
  }

  const photoTimeSetting  = () =>  {
    let photoTime = (document.querySelector('.PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement)
    if (photoTimeId !== "") {
      photoTime.value = photoTimeId;
    }
    else {
      photoTimeId = photoTime.value
    }
    if(photoTime.options[photoTime.options.selectedIndex]) {
        let photoOption = photoTime.options[photoTime.options.selectedIndex].textContent;
        (document.querySelector('.PhotoTime .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).value = photoOption;
    }
    else  {
      setPlaceHolderTime(text.placeholder_schedule)
    }
  }

  const meetingLocationSetting = () => {
    let selectInputElement = (document.querySelector('.MeetingLocation .SelectSweetEscape__SelectInput') as HTMLSelectElement)
    if(selectInputElement.value === '5') {
      (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).value = "Don't know yet"
      document.querySelector('.PhotoDetail__ErrorMessageDetail').classList.add('hidden')
      document.querySelector('.PhotoDetail__MeetingLocationDetail').classList.remove('error')
      document.querySelector('.PhotoDetail__MeetingLocationDetail').classList.add('hidden')
    }
    else {
      (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).value = null
      document.querySelector('.PhotoDetail__MeetingLocationDetail').classList.remove('hidden')

      if (props.main_order.photo_date !== null && props.main_order.main_order_snapshot.meeting_point !== "Don't know yet") { 
        (document.querySelector('.PhotoDetail__MeetingLocationDetail') as HTMLInputElement).value = props.main_order.main_order_snapshot.meeting_point
      }
    }
  }

  const showModal = () => {
    document.querySelector('.PhotoDetail__ModalMobile').classList.remove('hidden');
  }

  const closeModal  = () => {
    document.querySelector('.PhotoDetail__ModalMobile').classList.add('hidden');
  }
  const handleSubmitButton = () => {
    return (props.city.status !== "by-request") ? text.checkout_button : text.by_request
  }

  const getPhotoTime = async(date) => {
    let schedules_list = [],
        lastPhotoTime  = (document.querySelector('.PhotoTime .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement)
    getLastText = lastPhotoTime.getAttribute('placeholder')
    setPlaceHolderTime(null)
    document.querySelector('.PhotoDetail__Loading').classList.remove('hidden')
    setSelectedDate(date)
    if (isSchoolGraduationOrder) { setSelectedDate(new Date(2020, 6, 20)) }

    let schedule_id = (document.querySelector('.PhotoDetail__PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement).value,
        selectedDateFormat = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate(),
        expected_duration = (document.querySelector('.Reserve__PackageInput') as HTMLInputElement).value,
        price_without_discount = parseInt(document.querySelector('.selected-package').getAttribute('data-price-without-discount')),
        price = 0,
        slashPrice = 0

    if(price_without_discount > 0)
    {
      price = parseInt(document.querySelector('.selected-package').getAttribute('data-price-without-discount'))
      slashPrice = parseInt(document.querySelector('.selected-package').getAttribute('data-price').replace("USD ", ""))
    }else{
      price = parseInt(document.querySelector('.selected-package').getAttribute('data-price').replace("USD ", ""))
      slashPrice = 0
    }

    clevertapData = {slug: props.city.slug,
                     moments: props.moments.slug,
                     selectedDate: selectedDateFormat,
                     expected_duration: expected_duration,
                     price: price,
                     slashPrice: slashPrice,
                     order_code: order_code}
    if((document.querySelector('.PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement).value !== "") {
      photoTimeId = (document.querySelector('.PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement).value;
    }
    return await fetch(window.location.origin+"/api/v1/sessions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body:  JSON.stringify({
        "city_id": props.city.id,
        "order_id": props.main_order.main_order_snapshot.order_id,
        "date":  selectedDateFormat,
        "expected_duration": expected_duration
      })
    })
      .then(response => {
        return response.json()
      })
      .then(res => {
        triggerCleverTap(props.city.slug, props.moments.slug, selectedDateFormat, expected_duration, price, slashPrice, order_code);
        res.sessions.map(function(value, index) {
          let restructureTime = userFormatTime(value.start_hour)
          schedules_list.push({"value": value.id, "label": restructureTime,  "is_available": value.is_available })
          if(parseInt(photoTimeId) === value.id && !value.is_available) {
            photoTimeId = "";
            isAvailable = false;
          }
        })
        setSchedule(schedules_list)
        setGetScheduleLoaded(true)
        if (!isAvailable) {
          errorHandlerPhotoTime(schedule_id, isAvailable);
        }
      })
      .catch(error => console.log(error))
  }

  const userFormatTime  = (manipulatedTime) => {
    var hour = manipulatedTime % 12;
    hour = (hour === 0 ? 12 : hour)
    return (hour < 10 ? '0' : '') + hour + ':00' + (manipulatedTime < 12 ? ' AM' : ' PM');
  }

  const addBorderDatePicker = () => {
    document.querySelector(".PhotoDetail__DatePicker").setAttribute("readonly", "readonly")
    document.querySelector(".PhotoDetail__Arrow").setAttribute("src", "https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg")
    let firstMonth = (document.querySelector('.PhotoDetail__DatePickerCalender').lastElementChild.previousSibling as HTMLElement),
        lastMonth = (document.querySelector('.PhotoDetail__DatePickerCalender').lastElementChild as HTMLElement)
    if (lastMonth.offsetHeight < firstMonth.offsetHeight)  {
      lastMonth.classList.remove('left-border-datepicker')
      firstMonth.classList.add('right-border-datepicker')
    }
    else {
      firstMonth.classList.remove('right-border-datepicker')
      lastMonth.classList.add('left-border-datepicker')
    }
  }

  const changeArrowDown = () => {
    document.querySelector(".PhotoDetail__Arrow").setAttribute("src", "https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg")
  }

  const HandleSelect = () => {
    document.querySelector('.PhotoTime .SelectSweetEscape__SelectInput').addEventListener('click', function() {
      (document.querySelector('.PhotoTime .SelectSweetEscape__SelectDropDownWithIcon') as HTMLInputElement).classList.remove('error')
      document.querySelector('.PhotoDetail__ErrorMessagePhotoTime').classList.add('hidden')
      isAvailable = true;
      if (isSchoolGraduationOrder) {
        const scheduleId = (document.querySelector('.PhotoDetail__PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement)
        if (scheduleId) { setSelectedTime(scheduleId.value) }
        getBoothData()
      }
     })
  }

  const showAllBooths = booths.map((booth, index) => {
    return (
      <div className="PhotoDetail__BoothOuter col-xs-12" key={index}>
        <div className="PhotoDetail__BoothContainer col-xs-12">
          <label>
            <input type="radio" name="booth" value={booth.id} />
            <div className="PhotoDetail__BoothAttr">
              <div
                className="PhotoDetail__BoothBigImg"
                style={{
                  backgroundImage: `url(${booth.images[0]})`,
                }}
              />
              <div
                className="PhotoDetail__BoothSmallTopImg"
                style={{
                  backgroundImage: `url(${booth.images[1]})`,
                }}
              />
              <div
                className="PhotoDetail__BoothSmallBtmImg"
                style={{
                  backgroundImage: `url(${booth.images[2]})`,
                }}
              />
              <div className="PhotoDetail__BoothName">{booth.name}</div>
            </div>
          </label>
        </div>
        {index % 2 == 0 && (
          <div className="PhotoDetail__BoothSpace hidden-md-down" />
        )}
      </div>
    );
  });  

  const showEmptyBoothState = () => {
    let boothEmptyMsg;

    if (selectedTime && booths.length < 1) {
      boothEmptyMsg = "Sorry. No photo booth available at this time slot.";
    } else {
      boothEmptyMsg = "Choose photo time to show available photo booth.";
    }

    return (
      <div className="PhotoDetail__EmptyBooth">
        {boothEmptyMsg}
      </div>
    );  
  }

  const getDateValue = (dateValue) => {
    let date = (new Date(dateValue))
    let getTimeZone = (date.getTimezoneOffset() / -60)
    let convertedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), getTimeZone)
    return convertedDate;
  }

  const getBoothData = async() => {
    const scheduleId = (document.querySelector('.PhotoDetail__PhotoTime .SelectSweetEscape__SelectInput') as HTMLSelectElement).value
    let bookingDate = getDateValue((document.querySelector('.PhotoDetail__DatePicker') as HTMLInputElement).value);

    return await fetch(window.location.origin + '/api/v2/booth/availability', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'API-KEY': apiKey },
      body: JSON.stringify({ schedule_id: scheduleId, booking_date: bookingDate})
    }).then(response => response.json())
      .then(res => {
        const availableBooths = res["booths"].filter((booth) => booth.available)
        setBooths(availableBooths);
    }).catch(error => console.log(error));
  }

  useEffect(() => {
    if (!isSchoolGraduationOrder) { return }
    if (booths.length > 0) {
      document.querySelector('.PhotoDetail__BoothWrapper').classList.remove('hidden')
      document.querySelector('.PhotoDetail__EmptyBoothWrapper').classList.add('hidden')
    } else {
      document.querySelector('.PhotoDetail__EmptyBoothWrapper').classList.remove('hidden')
    }
  }, [booths])

  const boothSelection = (
    <div className="PhotoDetail__BoxWrapper">
      <div className="PhotoDetail__Box Additional__Margin">
        <div className="PhotoDetail__Header">
          Choose Your Photo Booth
        </div>
        <div className="PhotoDetail__SeparatorLine" />

        <div className="PhotoDetail__SelectBooth" >
          <div className="PhotoDetail__EmptyBoothWrapper col-xs-12">
            {showEmptyBoothState()}
          </div>

          <div className="PhotoDetail__ErrorMessageBooth hidden">Please choose your photo booth</div>
          
          <div className="PhotoDetail__BoothWrapper col-xs-12 hidden">
            {showAllBooths}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="PhotoDetail hidden container" >
      <div className="PhotoDetail__BoxWrapper">
        <div className="PhotoDetail__Box">

          <div className="PhotoDetail__Header" >
            {text.title}
          </div>

          <div className="PhotoDetail__SeparatorLine" />

          <div className="PhotoDetail__PhotoDate" >

            <div className="PhotoDetail__Label" >
              {parse(text.label_photo_date)}
            </div>

            <div className="PhotoDetail__DatePickerWrapper"> 

              <img className="PhotoDetail__Icon" src='https://cdn.sweetescape.com/misc/booking/ico_date@1x.svg' />
              
              <Datepicker 
                calendarClassName={`PhotoDetail__DatePickerCalender ${visibility}`}
                className = "PhotoDetail__DatePicker"
                selected = {selectedDate}
                placeholderText = {userFormatDate(minBookingDate)}
                onChange =  {date => getPhotoTime(date)}
                useWeekdaysShort={true}
                minDate = {minBookingDate}
                popperPlacement="bottom"
                popperModifiers={{
                  flip: {
                      behavior: ["bottom"] // don't allow it to flip to be above
                  }
                }}
                monthsShown  = {windowSize}
                dateFormat = "d MMMM yyyy"
              />

              <img className={`PhotoDetail__Arrow ${visibility}`} src='https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg' />
              <div className="PhotoDetail__ErrorMessagePhotoDate hidden" >Please choose correct photo date</div>
 
            </div>

          </div>

          <div className="PhotoDetail__PhotoTime" >

            <div className="PhotoDetail__Label" >
              {parse(text.label_photo_time)}
            </div>
            
            <Select
              version="select"
              options= {getSchedule}
              customClass= "PhotoTime"
              caret= {{"down": "https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg", "up": "https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg"}} 
              icon = "https://cdn.sweetescape.com/misc/booking/ico_starting_time@1x.svg"   
              placeholder = {getPlaceHolderTime}
              clevertapData = {clevertapData}
              onClick = {HandleSelect}
            />
            <img className="PhotoDetail__Loading hidden" src='https://cdn.sweetescape.com/misc/booking/loading-icon-booking.gif' />
            <div className="PhotoDetail__ErrorMessagePhotoTime hidden" >{text.photo_time_error}</div>
          </div>

        </div>
        
        <div className="hidden-md-down" >
          <div className="PhotoDetail__PackageDetail hidden-md-down" >

            <div className="PhotoDetail__Header">
              {text.title_package_detail} 
            </div>

            <div className="PhotoDetail__SeparatorLine" />

            <div className="PhotoDetail__PackageInfo">

              <div className="PhotoDetail__Label" >
                {!([null, "null"].includes(localStorage.getItem("otherValue")))
                ?
                  localStorage.getItem("otherValue")
                :
                  localize == 'de' ? `Urlaub` : props.moments.name
                }
              </div>

              <div className="PhotoDetail__City">
                <img src="https://cdn.sweetescape.com/misc/booking/ico_city-off@1x.svg" />
                
                <div className="PhotoDetail__Info">
                  {props.city.translated_name['en']+ ", "+ props.city.country_name['en'] }
                </div>
              </div>

              <div className="PhotoDetail__PhotoSession">
                <img src="https://cdn.sweetescape.com/misc/booking/ico_starting_time@1x.svg" />
                
                <div className="PhotoDetail__Info" />
              </div>

            </div>

            <div className="PhotoDetail__SeparatorLinePartially" />

            <div className="PhotoDetail__Subtotal">
              <div className="PhotoDetail__InfoSubtotal">
                {text.subtotal}
              </div>

              <div className="PhotoDetail__PriceDetail">
                <div className="PhotoDetail__PriceDesktop">
                  <div className="PhotoDetail__Price" />
                </div>
                {props.supportLocalizedCurrency &&
                  <div className={`PhotoDetail__LocalizePrice ${isSchoolGraduationOrder && `hidden`}`} />
                }
              </div>

            </div>
            <div className="PhotoDetail__SubmitWrapper">
              <div onClick={submitOrder} className="PhotoDetail__Submit">{handleSubmitButton()}</div>
              <img className="PhotoDetail__RollLoading hidden" src="https://cdn.sweetescape.com/misc/booking/roll-loading-booking.gif" />
            </div>
          </div>

          <div className={`PhotoDetail__Policies hidden-md-down ${isSchoolGraduationOrder && `addHeight`}`}>
            {!isSchoolGraduationOrder &&
              <Policies variant="BookingInformation" />
            }
          </div>
        </div>

      </div>
      {props.isSchoolGraduationOrder && boothSelection}
      <div className={`PhotoDetail__BoxWrapper ${visibility}`}>
        <div className="PhotoDetail__Box Additional__Margin">

          <div className="PhotoDetail__Header" >
            {text.title_2}
          </div>

          <div className="PhotoDetail__SeparatorLine" />

          <div className="PhotoDetail__MeetingLocation" >

            <div className="PhotoDetail__Label" >
              {parse(text.label_meeting_location)}
            </div>

            <SelectCustom
              order_code= {order_code}
              options={meetingLocationList}
              customClass="MeetingLocation"
              caret= {{"down": "https://cdn.sweetescape.com/misc/booking/ico-dropdown-down.svg", "up": "https://cdn.sweetescape.com/misc/booking/ico-dropdown-up-new.svg"}} 
              icon = "https://cdn.sweetescape.com/misc/booking/ico_city-off@1x.svg"
              placeholder = {text.placeholder_meeting_location}
              clevertapData = {clevertapData}
            />
            <div className="PhotoDetail__ErrorMessageMeetingLocation hidden" >{text.meeting_location_error}</div>
            
            <input type="text" placeholder={text.placeholder_detail} id="PhotoDetail__MeetingLocationDetail" className="PhotoDetail__MeetingLocationDetail" />
            <div className="PhotoDetail__ErrorMessageDetail hidden" >{text.meeting_location_detail_error}</div>
  
          </div>

          <div className="PhotoDetail__AdditionalInfo" >

            <div className="PhotoDetail__Label" >
              {parse(text.label_additional_info)}
            </div>
            <textarea defaultValue={props.main_order.main_order_snapshot.note} id="PhotoDetail__AdditionalInfo" className="PhotoDetail__AdditionalInfo" placeholder={text.main_order_placeholder} />
          </div>

        </div>
      </div>
      {!isSchoolGraduationOrder &&
        <div className="hidden-lg-up">
          <Policies variant="PhotoDetails" />
        </div>
      }

      <div className="PhotoDetail__PackageDetailMobile hidden-lg-up">
        <div className="PhotoDetail__DetailMobile">
          <div className="PhotoDetail__PriceContainer">
            <div className="PhotoDetail__PriceMobileWrapper">
              <div className="PhotoDetail__PriceMobile" />
            </div>
            {props.supportLocalizedCurrency &&
              <div className="PhotoDetail__LocalizePriceMobile" />
            }
          </div>
          <div className="PhotoDetail__SeeDetails" onClick={showModal}>
            {text.see_details}
          </div>
        </div>
        <div className="PhotoDetail__SubmitMobileFloat">
          <div onClick={submitOrder} className="PhotoDetail__ButtonMobile">
            {handleSubmitButton()}
          </div>
          <img className="PhotoDetail__RollLoadingMobile hidden" src="https://cdn.sweetescape.com/misc/booking/roll-loading-booking.gif" />
        </div>
      </div>

      <div className="PhotoDetail__ModalMobile hidden">
        <div className="PhotoDetail__Header Modal__Header">
          <img onClick={closeModal} src="https://cdn.sweetescape.com/misc/booking/ico_close@1x.svg" />
          {text.title_package_detail}
        </div>

        <div className="PhotoDetail__Label Modal__Label">
          {!([null, "null"].includes(localStorage.getItem("otherValue")))
          ?
            localStorage.getItem("otherValue")
          :
            localize == 'de' ? `Urlaub` : props.moments.name
          }
        </div>

        <div className="PhotoDetail__InformationMobile">
          <div className="PhotoDetail__LabelMobile">
            {text.title_photo_location}
            <span>{props.city.translated_name['en']+ ", "+ props.city.country_name['en'] }</span>
          </div>

          <div className="PhotoDetail__SeparatorLine"  />
          <div className="PhotoDetail__LabelMobile Additional__Label">
            {text.title_package}
          </div>

          <div className="PhotoDetail__Duration">
            <img src="https://cdn.sweetescape.com/misc/booking/ico_starting_time@1x.svg" />
            <span> </span>
          </div>

          <div className="PhotoDetail__EditedPhotos">
            <img src="https://cdn.sweetescape.com/misc/booking/ico-edited.svg" />
            <span>{text.edited_photos}</span>
          </div>

          <div className="PhotoDetail__Downloadable">
            <img src="https://cdn.sweetescape.com/misc/booking/ico-downloadables.svg" />
            <span>{text.downloadable}</span>
          </div>

          {selectedPackage &&
            selectedPackage.getAttribute("data-index") === "2" && (
              <div className="PhotoDetail__Downloadable">
                <img className="printerous-logo" src="https://cdn.sweetescape.com/misc/landing/binus-graduation/logo_printerous.png" />
                <span>Photo Print Voucher on Printerous</span>
              </div>
            )}
        </div>

        {!isSchoolGraduationOrder &&
          <div className="PhotoDetail__PoliciesMobile hidden-md-up">
            <Policies variant="BookingInformation" />
          </div>
        }
      </div>
    </div>
  );
}

export default PhotoDetail